import gql from 'graphql-tag';

export const getVideo = gql`
  query getVideo($id: ID!) {
    video(id: $id) @client {
      thumbnail_attachment {
        id
        metadata
        url
      }
    }
  }
`;
