import gql from 'graphql-tag';

export const getNowPlaying = gql`
  query getNowPlaying {
    nowPlaying @client {
      chapterId
      courseEnrollmentId
      videoId
      videoTimestamp
      videoUrl
    }
  }
`;
