import React from 'react';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import Loader from './Loader';

export default ({ children, ...props }) => {
  return (
    <Query {...props}>
      {({ loading, error, data, client }) => {
        if (loading) {
          return <Loader />;
        }

        if (error) {
          return <Redirect to="/login" />;
        }

        return children({ ...data, client });
      }}
    </Query>
  );
};
