const latestProgressOrFirstVideo = (enrollment, progresses) => {
  if (progresses.length > 0) {
    const { currentVideo } = progresses.sort((a, b) => {
      const aDate = new Date(a.updatedAt);
      const bDate = new Date(b.updatedAt);

      return bDate - aDate;
    })[0];

    return {
      classId: enrollment.course.id,
      videoId: currentVideo.id,
    };
  } else {
    return {
      classId: enrollment.course.id,
      videoId: enrollment.course.chapters[0].videos[0].id,
    };
  }
};

export default latestProgressOrFirstVideo;
