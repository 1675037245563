import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import logo from '../images/logo.svg';
import Colors from '../util/Colors';

const Logo = styled.div`
  display: flex;

  a {
    width: 120px;
  }

  svg {
    max-height: 40px;
    fill: ${Colors.navy};

    @media screen and (min-width: 1088px) {
      fill: ${Colors.white};
    }

    .logo__heart {
      fill: ${Colors.yellow};

      @media screen and (min-width: 1088px) {
        fill: ${Colors.white};
      }
    }
  }
`;

export default function(props) {
  return (
    <Logo {...props}>
      <Link to="/">
        <SVG src={logo} alt="Happily Logo" />
      </Link>
    </Logo>
  );
}
