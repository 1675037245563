import gql from 'graphql-tag';

export const getCurrentVideo = gql`
  query {
    currentVideo @client {
      id
      time
      chapter {
        id
        chapterNumber
        title
      }
    }
  }
`;
