import gql from 'graphql-tag';
import { PROGRESS_DATA, VIDEO_DATA } from '../../graphql';

export const defaults = {};

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    allProgresses: [CourseProgress]
    allVideos: [Video]
    video(id: ID!): Video
    courseProgresses: [CourseProgress]
    courseProgress(id: ID!): CourseProgress
  }
`;

export const resolvers = {
  Query: {
    allVideos: ({ courseEnrollments }, _args, { cache }) => {
      return courseEnrollments
        .map((e) => e.course.chapters.map((c) => c.videos.map((v) => v)).flat())
        .flat();
    },
    video: (data, { id }, { cache }) => {
      const { allVideos } = cache.readQuery({
        query: gql`
          {
            allVideos @client {
              ...VideoData
            }
          }
          ${VIDEO_DATA}
        `,
      });

      const video = allVideos.find((v) => v.id === id);

      return video;
    },
    allProgresses: ({ courseEnrollments }, _args, { cache }) => {
      return courseEnrollments.map((e) => e.courseProgresses).flat();
    },
    courseProgresses: ({ courseEnrollment }, _args, { cache }) => {
      return courseEnrollment.courseProgresses;
    },
    courseProgress: (data, { id }, { cache }) => {
      const { courseProgresses } = cache.readQuery({
        query: gql`
          {
            courseProgresses @client {
              ...ProgressData
            }
          }
          ${PROGRESS_DATA}
        `,
      });

      const progress = courseProgresses.find((p) => p.id === id);

      return progress;
    },
  },
};
