import gql from 'graphql-tag';

export const getMyCurrentCourse = gql`
  {
    me {
      courseEnrollments {
        id
        updatedAt
        course {
          id
          title
          description
        }
      }
    }
  }
`;
