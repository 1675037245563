import React, { Component } from 'react';
import { Columns, Column } from 'bloomer';
import gql from 'graphql-tag';
import styled from 'styled-components';

import Query from '../components/Query';
import ChapterAccordion from '../components/ChapterAccordion';
import Player from '../components/Player';
import VideoDetails from '../components/VideoDetails';
import VideoList from '../components/VideoList';
import VideoAttachments from '../components/VideoAttachments';
import LayoutLesson from '../components/Layout/Lesson';

import { getLessonInfo } from '../graphql';

const LessonContent = styled.div`
  margin-bottom: 5rem;
  padding: 0 25px;

  @media screen and (min-width: 1088px) {
    padding: 0 90px;
  }
`;

class LessonScreen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Query query={getLessonInfo} variables={{ id: this.props.enrollmentId }}>
        {({ courseEnrollment, client }) => {
          const { courseProgresses } = courseEnrollment;
          const videoProgress = courseProgresses.filter((progress) => {
            return progress.currentVideo.id === this.props.videoId;
          })[0];
          const currentVideo = client.readFragment({
            id: this.props.videoId,
            fragment: gql`
              fragment video on Chapter {
                id
                title
                summary
                description
                position
                videoUrl
                chapter {
                  id
                }
                attachments {
                  id
                  metadata
                  url
                  filename
                }
              }
            `,
          });

          const videoList = courseEnrollment.course.chapters
            .sort((a, b) => {
              return a.chapterNumber - b.chapterNumber;
            })
            .map((c) => c.videos)
            .flat();

          const nextVideo =
            videoList[videoList.findIndex((v) => v.id === currentVideo.id) + 1];

          return (
            <LayoutLesson>
              <Columns isGapless>
                <Column isSize={3} isHidden="touch">
                  <ChapterAccordion
                    courseEnrollment={courseEnrollment}
                    location={this.props.location}
                    isHidden="touch"
                  />
                </Column>
                <Column isSize={{ mobile: 12, desktop: 9 }}>
                  <Player
                    videoUrl={currentVideo.videoUrl}
                    enrollmentId={courseEnrollment.id}
                    chapterId={currentVideo.chapter.id}
                    videoId={this.props.videoId}
                    {...videoProgress}
                  />
                  <VideoDetails
                    currentVideo={currentVideo}
                    nextVideo={nextVideo}
                    location={this.props.location}
                  />
                </Column>
              </Columns>
              <Columns
                isGapless
                isMultiline
                isHidden={['desktop', 'widescreen']}
              >
                {currentVideo.attachments.length > 0 && (
                  <Column isSize={12}>
                    <LessonContent>
                      <h2>Lesson Content</h2>
                      <VideoAttachments
                        attachments={currentVideo.attachments}
                      />
                    </LessonContent>
                  </Column>
                )}
                <Column isSize={12}>
                  <VideoList
                    videoList={videoList}
                    currentVideoId={currentVideo.id}
                    location={this.props.location}
                  />
                </Column>
              </Columns>
            </LayoutLesson>
          );
        }}
      </Query>
    );
  }
}

export default LessonScreen;
