import gql from 'graphql-tag';
import { PROGRESS_DATA } from './ProgressData';
import { COURSE_DATA } from './CourseData';

export const getLessonInfo = gql`
  query LessonEnrollment($id: ID!) {
    courseEnrollment(id: $id) {
      id
      updatedAt
      course {
        ...CourseData
      }
      courseProgresses {
        ...ProgressData
      }
    }
  }
  ${COURSE_DATA}
  ${PROGRESS_DATA}
`;
