import gql from 'graphql-tag';
import { PROGRESS_DATA } from './ProgressData';
import { COURSE_DATA } from './CourseData';
import { VIDEO_DATA } from './VideoData';

export const getIndexInfo = gql`
  query IndexQuery {
    courseEnrollments {
      id
      createdAt
      course {
        ...CourseData
      }
      courseProgresses {
        ...ProgressData
      }
    }
    allProgresses @client {
      ...ProgressData
    }
    allVideos @client {
      ...VideoData
    }
  }
  ${COURSE_DATA}
  ${PROGRESS_DATA}
  ${VIDEO_DATA}
`;
