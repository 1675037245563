import gql from 'graphql-tag';

export const getEnrollment = gql`
  query getEnrollment($id: ID!) {
    courseEnrollment(id: $id) {
      id
      course {
        chapters {
          id
          chapterNumber
          title
          videos {
            id
            description
            title
            videoUrl
          }
        }
      }
      courseProgresses {
        chapterId
        completed
        videoId
        videoTimestamp
      }
    }
  }
`;
