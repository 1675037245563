import React from 'react';
import { Hero, HeroHeader, HeroBody, Container } from 'bloomer';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Colors from '../util/Colors';
import default_thumbnail from '../images/default_thumbnail.png';

import Navigation from './Navigation';
import PlayButton from './PlayButton';

const styleBackground = (image) => {
  return image
    ? `linear-gradient(to right, rgba(0,0,0,1) 55vw, rgba(0,0,0,0.5)) , url(${image}) no-repeat top right black`
    : Colors.black;
};

const StyledHeroBody = styled(HeroBody)`
  @media screen and (min-width: 1088px) {
    background: ${(props) => styleBackground(props.bg)};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: contain;
  }
`;

const ClassInfo = styled.div`
  h1 {
    color: ${Colors.white};
    margin-bottom: 0.5rem;
    max-width: 40%;
  }

  h6 {
    color: ${Colors.yellow};
  }

  a {
    margin-top: 2rem;
  }
`;

const StyledHeroHeader = styled(HeroHeader)`
  background: ${Colors.white};

  @media screen and (min-width: 1088px) {
    position: absolute;
    width: 100%;
    background: transparent;
    color: ${Colors.white};
  }
`;

const Header = (props) => {
  return (
    <Hero isSize="medium">
      <StyledHeroHeader>
        <Navigation />
      </StyledHeroHeader>
      <StyledHeroBody
        bg={
          props.video.thumbnail_attachment
            ? props.video.thumbnail_attachment.url
            : default_thumbnail
        }
        isHidden="touch"
      >
        <Container>
          <ClassInfo>
            <h1>{props.title}</h1>
            <h6>{props.subtitle}</h6>
            <Link
              to={{
                pathname: props.link.url,
                state: {
                  enrollmentId: props.link.enrollmentId,
                  videoId: props.videoId,
                },
              }}
              className="c-button"
            >
              {props.link.text}
            </Link>
          </ClassInfo>
        </Container>
        <PlayButton
          url={props.link.url}
          enrollmentId={props.link.enrollmentId}
          videoId={props.videoId}
        />
      </StyledHeroBody>
    </Hero>
  );
};

export default Header;
