import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const setStateFromParams = (location) => {
  const urlParams = new URLSearchParams(location.search);
  const urlEnrollmentId = urlParams.get('enrollment_id');
  const urlToken = urlParams.get('token');
  const urlVideo = urlParams.get('video_id');

  if (urlVideo) {
    location.state = { ...location.state, videoId: urlVideo };
  }

  if (urlEnrollmentId) {
    location.state = { ...location.state, enrollmentId: urlEnrollmentId };
  }

  if (urlToken) {
    localStorage.setItem('token', urlToken);
  }
};

const PrivateRoute = ({ component: Component, client, location, ...rest }) => {
  setStateFromParams(location);
  const enrollmentId = location.state && location.state.enrollmentId;
  const videoId = location.state && location.state.videoId;
  const token = localStorage.getItem('token');

  return (
    <Route
      {...rest}
      location={location}
      render={(props) => {
        return token ? (
          <Component videoId={videoId} enrollmentId={enrollmentId} {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default PrivateRoute;
