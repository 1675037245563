import React from 'react';
import { withHelpersModifiers, Container, Columns, Column } from 'bloomer';
import styled from 'styled-components';

import latestProgressOrFirstVideo from '../util/latestProgressOrFirstVideo';

import ClassThumbnail from '../components/ClassThumbnail';

const SectionHeading = styled.h1`
  margin-bottom: 20px;
`;

const MyClasses = (props) => {
  return (
    <Container className={props.className}>
      <SectionHeading>My Online Experiences</SectionHeading>
      <Columns isMultiline>
        {props.courseEnrollments.length
          ? props.courseEnrollments.map((enrollment) => {

          if(enrollment.course.chapters[0] && enrollment.course.chapters[0].videos[0]) {
            return (
              <Column key={enrollment.id} isSize="1/2">
                <ClassThumbnail
                  title={enrollment.course.title}
                  classId={enrollment.course.id}
                  videoId={enrollment.course.chapters[0].videos[0].id}
                  detail={{
                    type: 'link',
                    data: `/classes/${enrollment.course.id}`,
                    enrollmentId: enrollment.id,
                  }}
                  playButton
                />
              </Column>
            );
          } else {
            return(null);
          }

        } ) : null}
      </Columns>
    </Container>
  );
};

export default withHelpersModifiers(MyClasses);
