import gql from 'graphql-tag';
import { VIDEO_DATA } from './VideoData';

export const COURSE_DATA = gql`
  fragment CourseData on Course {
    id
    title
    summary
    description
    chapters {
      id
      chapterNumber
      title
      summary
      description
      videos {
        ...VideoData
      }
    }
  }
  ${VIDEO_DATA}
`;
