import React, { Component } from 'react';
import styled from 'styled-components';

import Header from '../Header';
import Footer from '../Footer';
import Query from '../Query';
import { getVideo } from '../../graphql';

const Main = styled.div`
  @media screen and (min-width: 1088px) {
    padding-bottom: 10rem;
  }
`;

export default class LayoutMain extends Component {
  render() {
    return (
      <Query query={getVideo} variables={{ id: this.props.videoId }}>
        {({ video }) => {
          return (
            <>
              <Header {...this.props} video={video} />
              <Main>{this.props.children}</Main>
              <Footer />
            </>
          );
        }}
      </Query>
    );
  }
}
