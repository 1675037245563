import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import IndexScreen from '../screens/IndexScreen';
import LessonScreen from '../screens/LessonScreen';
import ClassScreen from '../screens/ClassScreen';
import LoginScreen from '../screens/LoginScreen';
import PrivateRoute from '../util/PrivateRoute';

class Routes extends Component {
  render() {
    return (
      <>
        <Switch>
          <PrivateRoute exact path="/" component={IndexScreen} />
          <PrivateRoute
            exact
            path="/classes/:class_id/lessons/:lesson_id"
            component={LessonScreen}
          />
          <PrivateRoute
            exact
            path="/classes/:class_id"
            component={ClassScreen}
          />
          <Route exact path="/login" component={LoginScreen} />
          <Route path="/login/:token" component={LoginScreen} />
          <Route path="*" render={() => <h1>404</h1>} />
        </Switch>
      </>
    );
  }
}

export default Routes;
