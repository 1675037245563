import gql from 'graphql-tag';

export const getLatestProgress = gql`
  {
    latestProgress {
      videoTimestamp
      courseEnrollmentId
      chapterId
      completed
      videoId
      currentVideo {
        title
        description
        videoUrl
      }
      courseEnrollment {
        id
        course {
          chapters {
            id
            chapterNumber
            title
            videos {
              id
              title
              videoUrl
            }
          }
        }
        courseProgresses {
          chapterId
          completed
          videoId
          videoTimestamp
        }
      }
    }
  }
`;
