import React, { Component } from 'react';
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarItem,
  NavbarMenu,
  NavbarEnd,
} from 'bloomer';
import styled from 'styled-components';

import LessonLogo from './LessonLogo';
import Colors from '../util/Colors';

const Link = styled.a`
  color: ${Colors.navy};
  display: flex;
  align-items: center;
  margin-right: 1rem;
  text-transform: uppercase;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    color: ${Colors.navyDarker};
  }
`;

class LessonNavigation extends Component {
  render() {
    return (
      <Navbar>
        <Container>
          <NavbarBrand>
            <NavbarItem>
              <LessonLogo className="default" />
            </NavbarItem>
          </NavbarBrand>
          <NavbarMenu>
            <NavbarEnd>
              <NavbarItem
                isHidden="touch"
                render={() => {
                  return (
                    <Link
                      href={`${
                        process.env.REACT_APP_SERVER_URL
                      }/account/personal`}
                    >
                      Account
                    </Link>
                  );
                }}
              />
              <NavbarItem
                isHidden="touch"
                render={() => (
                  <Link href={process.env.REACT_APP_SERVER_URL}>
                    Happily Home
                  </Link>
                )}
              />
            </NavbarEnd>
          </NavbarMenu>
        </Container>
      </Navbar>
    );
  }
}

export default LessonNavigation;
