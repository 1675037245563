import gql from 'graphql-tag';

export const getMyEnrollments = gql`
  {
    me {
      courseEnrollments {
        id
        course {
          title
          description
          summary
          chapters {
            id
            videos {
              id
              videoUrl
            }
          }
        }
        courseProgresses {
          chapterId
          videoId
          completed
          courseEnrollmentId
          videoTimestamp
          updatedAt
          currentVideo {
            description
            title
            videoUrl
          }
        }
      }
    }
  }
`;
