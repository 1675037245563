import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'bloomer';
import styled from 'styled-components';

import { getVideo } from '../graphql';
import default_thumbnail from '../images/default_thumbnail.png';

import PlayButton from './PlayButton';
import Query from './Query';

const StyledClassThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .class__detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    h6 {
      font-weight: 400;
    }

    h4 {
      margin-left: 1rem;
    }
  }
`;

const detailSwitch = (detail) => {
  switch (detail.type) {
    case 'price':
      return <h4>{detail.data}</h4>;
    case 'time':
      return <h4>{detail.data}</h4>;
    case 'link':
      return (
        <Link
          to={{
            pathname: detail.data,
            state: { enrollmentId: detail.enrollmentId },
          }}
        >
          <h4>Online experiences home</h4>
        </Link>
      );
    default:
      return '';
  }
};

class ClassThumbnail extends Component {
  render() {
    return (
      <Query query={getVideo} variables={{ id: this.props.videoId }}>
        {({ video }) => {
          return (
            <StyledClassThumbnail style={this.props.style}>
              <div className="class__image">
                <Image
                  isRatio="3:2"
                  src={
                    video.thumbnail_attachment
                      ? video.thumbnail_attachment.url
                      : default_thumbnail
                  }
                />
                {this.props.playButton && (
                  <PlayButton
                    url={`/classes/${this.props.classId}/lessons/${
                      this.props.videoId
                    }`}
                    videoId={this.props.videoId}
                    enrollmentId={this.props.detail.enrollmentId}
                  />
                )}
              </div>
              <div className="class__detail">
                <h6>{this.props.title}</h6>
                {detailSwitch(this.props.detail)}
              </div>
            </StyledClassThumbnail>
          );
        }}
      </Query>
    );
  }
}

export default ClassThumbnail;
