import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Colors from '../util/Colors';

const List = styled.div`
  margin: 0 0 5rem;
  padding: 0 25px 2rem;
`;

const Video = styled.li`
  margin: 1rem 0;

  &:last-of-type {
    border-bottom: 1px solid ${Colors.yellow};
    padding-bottom: 1.5rem;
  }

  a {
    color: ${Colors.navy};
  }
`;

const Title = styled.span`
  font-weight: 600;
  color: ${Colors.green};
`;

const VideoList = (props) => {
  return (
    <List>
      <h2>All Videos</h2>
      <ul>
        {props.videoList.map((video) => {
          return (
            <Video key={video.id}>
              {video.id === props.currentVideoId ? (
                <Title>{video.title}</Title>
              ) : (
                <Link
                  to={{
                    pathname: video.chapter.id,
                    state: {
                      ...props.location.state,
                      videoId: video.id,
                    },
                  }}
                  replace
                >
                  {video.title}
                </Link>
              )}
            </Video>
          );
        })}
      </ul>
    </List>
  );
};

export default VideoList;
