import Color from 'color';

const white = Color('#ffffff');
const black = Color('#000000');
const gray = Color('#637386');
const yellow = Color('#FBC94A');
const navy = Color('#000B8C');
const blue = Color('#89D2D9');
const green = Color('#72c7a8');
const red = Color('#F0483E');

const success = Color('#50b83c');
const warning = Color('#eec200');
const danger = Color('#ed6347');

export default {
  white: white.string(),
  whiteDark: Color('#fef9ec').string(),
  black: black.string(),

  grayLightest: gray.lighten(1.15).string(),
  grayLighter: gray.lighten(0.75).string(),
  grayLight: gray.lighten(0.25).string(),
  gray: gray.string(),
  grayDark: gray.darken(0.1).string(),
  grayDarker: gray.darken(0.25).string(),

  yellowLighter: Color('#fef9ec').string(),
  yellowLight: yellow.lighten(0.25).string(),
  yellow: yellow.string(),
  yellowDark: Color('#ffb700').string(),
  yellowDarker: yellow.darken(0.35).string(),

  navyLighter: navy.lighten(0.45).string(),
  navyLight: navy.lighten(0.25).string(),
  navy: navy.string(),
  navyDark: navy.darken(0.15).string(),
  navyDarker: navy.darken(0.25).string(),

  blueLighter: blue.lighten(0.45).string(),
  blueLight: blue.lighten(0.25).string(),
  blue: blue.string(),
  blueDark: blue.darken(0.15).string(),
  blueDarker: blue.darken(0.25).string(),

  greenLighter: green.lighten(0.45).string(),
  greenLight: green.lighten(0.25).string(),
  green: green.string(),
  greenDark: green.darken(0.15).string(),
  greenDarker: green.darken(0.25).string(),

  redLighter: red.lighten(0.45).string(),
  redLight: red.lighten(0.25).string(),
  red: red.string(),
  redDark: Color('#d40c00').string(),
  redDarker: red.darken(0.25).string(),

  successLighter: success.lighten(0.45).string(),
  successLight: success.lighten(0.25).string(),
  success: success.string(),
  successDark: success.darken(0.15).string(),
  successDarker: success.darken(0.25).string(),

  warningLighter: warning.lighten(0.45).string(),
  warningLight: warning.lighten(0.25).string(),
  warning: warning.string(),
  warningDark: warning.darken(0.25).string(),
  warningDarker: warning.darken(0.45).string(),

  dangerLighter: danger.lighten(0.45).string(),
  dangerLight: danger.lighten(0.25).string(),
  danger: danger.string(),
  dangerDark: danger.darken(0.15).string(),
  dangerDarker: danger.darken(0.25).string(),
};
