import React from 'react';
import styled from 'styled-components';
import { Container, Columns, Column } from 'bloomer';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import SVG from 'react-inlinesvg';

import chevron from '../images/chevron.svg';
import Colors from '../util/Colors';

const Footer = styled.footer`
  background: ${Colors.black};
  color: ${Colors.white};
  padding: 3rem 1.5rem 0;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ListHeader = styled.li`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.75rem;
  list-style: none;

  a {
    color: ${Colors.white};

    &:hover {
      color: ${Colors.whiteDark};
    }
  }
`;

const Copyright = styled.p`
  text-align: center;
  color: ${Colors.white};
`;

const menuItems = [
  {
    title: 'Online Experiences',
    url: `${process.env.REACT_APP_SERVER_URL}/online-experiences`,
  },
  {
    title: 'Account',
    url: `${process.env.REACT_APP_SERVER_URL}/account/personal`,
  },
  {
    title: 'Happily Home',
    url: `${process.env.REACT_APP_SERVER_URL}`,
  },
  {
    title: 'Help center',
    url: 'https://support.thehappily.co/',
  },
];

const aboutItems = [
  {
    title: 'Terms of service',
    url: `${process.env.REACT_APP_SERVER_URL}/terms`,
  },
  {
    title: 'Privacy Policy',
    url: `${process.env.REACT_APP_SERVER_URL}/policy`,
  },
  {
    title: 'FAQ',
    url: `${process.env.REACT_APP_SERVER_URL}/FAQ`,
  },
];

const createListItem = (item, classes) => (
  <ListItem key={item.url} className={classes}>
    <a href={item.url}>{item.title}</a>
  </ListItem>
);

const menuNodes = menuItems.map((item) =>
  createListItem(item, 'accordion__half-item')
);
const aboutNodes = aboutItems.map((item) =>
  createListItem(item, 'accordion__half-item')
);

export default (props) => {
  return (
    <Footer id="footer">
      <Container
        isFluid
        style={{ margin: 0 }}
        isHidden={['desktop', 'widescreen']}
      >
        <Accordion className="accordion accordion--white">
          <AccordionItem
            className="accordion__item accordion__item--white"
            expanded
          >
            <AccordionItemTitle className="lesson__title lesson__title--white">
              Menu
              <div className="lesson__arrow lesson__arrow--white">
                <SVG src={chevron} alt="chevron" />
              </div>
            </AccordionItemTitle>
            <AccordionItemBody className="accordion__body accordion__body--half">
              {menuNodes}
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem className="accordion__item accordion__item--white">
            <AccordionItemTitle className="lesson__title lesson__title--white">
              About
              <div className="lesson__arrow lesson__arrow--white">
                <SVG src={chevron} alt="chevron" />
              </div>
            </AccordionItemTitle>
            <AccordionItemBody className="accordion__body accordion__body--half">
              {aboutNodes}
            </AccordionItemBody>
          </AccordionItem>
        </Accordion>
      </Container>
      <Container isHidden="touch">
        <Columns>
          <Column isSize="1/4">
            <ul>
              <ListHeader>Menu</ListHeader>
              {menuNodes}
            </ul>
          </Column>
          <Column isSize="1/4">
            <ul>
              <ListHeader>About</ListHeader>
              {aboutNodes}
            </ul>
          </Column>
        </Columns>
      </Container>
      <Container>
        <Copyright> &copy; Copyright {new Date().getFullYear()} </Copyright>
      </Container>
    </Footer>
  );
};
