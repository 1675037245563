import React from 'react';
import { Image } from 'bloomer';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withHelpersModifiers } from 'bloomer';

import default_thumbnail from '../images/default_thumbnail.png';

import PlayButton from './PlayButton';

const latestProgressOrFirstVideo = (enrollment, progresses) => {
  if (progresses.length > 0) {
    // Get latest updated progress
    const { currentVideo } = progresses.sort((a, b) => {
      const aDate = new Date(a.updatedAt);
      const bDate = new Date(b.updatedAt);

      return bDate - aDate;
    })[0];
    return currentVideo;
  } else {
    return enrollment.course.chapters[0].videos[0];
  }
};

const StyledThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.h3`
  margin-bottom: 1rem;
`;

const Button = styled(Link)`
  margin: 1rem auto 0;
`;

const LatestVideo = (props) => {
  const video = latestProgressOrFirstVideo(props.enrollment, props.progresses);
  return (
    <StyledThumbnail className={props.className}>
      <Title>{video.title}</Title>
      <div className="class__image">
        <Image
          isRatio="3:2"
          src={
            video.thumbnail_attachment
              ? video.thumbnail_attachment.url
              : default_thumbnail
          }
        />
        <PlayButton
          url={`/classes/${props.enrollment.course.id}/lessons/${video.id}`}
          videoId={video.id}
          enrollmentId={props.enrollment.id}
        />
      </div>
      <Button
        to={{
          pathname: `/classes/${props.enrollment.course.id}/lessons/${
            video.id
          }`,
          state: {
            enrollmentId: props.enrollment.id,
            videoId: video.id,
          },
        }}
        className="c-button c-button--yellow"
      >
        Resume
      </Button>
    </StyledThumbnail>
  );
};

export default withHelpersModifiers(LatestVideo);
