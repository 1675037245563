import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import { persistCache } from 'apollo-cache-persist';

import { resolvers, typeDefs, defaults } from './resolvers';

const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.id,
});

persistCache({
  cache,
  storage: window.localStorage,
});

const request = async (operation) => {
  const token = await localStorage.getItem('token');
  operation.setContext({
    headers: {
      Authorization: token,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }
      if (networkError) {
        localStorage.removeItem('token');
        console.log('[Network Error]:', networkError);
      }
    }),
    requestLink,
    new HttpLink({
      uri: `${process.env.REACT_APP_SERVER_URL}/api/graphql`,
      credentials: 'include',
    }),
  ]),
  defaults,
  typeDefs,
  resolvers,
  cache,
});

const data = {
  isLoggedIn: !!localStorage.getItem('token'),
};

cache.writeData({ data });

client.onResetStore(() => cache.writeData({ data }));

export default client;
