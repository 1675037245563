import gql from 'graphql-tag';

export const VIDEO_DATA = gql`
  fragment VideoData on Video {
    id
    title
    description
    summary
    lengthInSeconds
    position
    videoUrl
    thumbnail_attachment {
      id
      metadata
      url
    }
    chapter {
      id
      chapterNumber
    }
    attachments {
      id
      url
      metadata
      filename
    }
  }
`;
