import gql from 'graphql-tag';

export const PROGRESS_DATA = gql`
  fragment ProgressData on CourseProgress {
    id
    updatedAt
    currentVideo {
      id
      title
      description
      summary
      lengthInSeconds
      position
      videoUrl
      thumbnail_attachment {
        id
        metadata
        url
      }
      chapter {
        id
        chapterNumber
      }
      attachments {
        id
        url
        metadata
      }
    }
    videoTimestamp
    completed
  }
`;
