import gql from 'graphql-tag';
import { PROGRESS_DATA } from './ProgressData';

export const updateProgress = gql`
  mutation updateProgress($update_course_progress: UpdateCourseProgressInput!) {
    updateCourseProgress(input: $update_course_progress) {
      courseProgress {
        ...ProgressData
      }
    }
  }
  ${PROGRESS_DATA}
`;
