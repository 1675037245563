import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';

import { updateProgress } from '../graphql';

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const RPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100vh;
`;

class Player extends Component {
  ref = (player) => {
    this.player = player;
  };

  render() {
    return (
      <Mutation mutation={updateProgress}>
        {(updateProgress) => {
          return (
            <PlayerWrapper>
              <RPlayer
                ref={this.ref}
                url={this.props.videoUrl}
                width="100%"
                height="100%"
                config={{
                  wistia: {
                    options: {
                      playerColor: '72c7a8',
                      silentAutoPlay: 'allow',
                      videoFoam: true,
                    },
                  },
                  youtube: {
                    playerVars: {
                      controls: 1,
                    },
                  },
                }}
                playing
                onReady={() => {
                  this.player.seekTo(
                    this.props.completed ? 0 : this.props.videoTimestamp || 0
                  );
                }}
                onPlay={() => {
                  updateProgress({
                    variables: {
                      update_course_progress: {
                        courseEnrollmentId: this.props.enrollmentId,
                        chapterId: this.props.chapterId,
                        videoId: this.props.videoId,
                        completed: false,
                      },
                    },
                  });
                }}
                onProgress={({ playedSeconds, played }) => {
                  const seconds = Math.floor(playedSeconds);
                  const percent = Math.round(played * 100);
                  if (percent % 5 === 0 && seconds !== 0) {
                    updateProgress({
                      variables: {
                        update_course_progress: {
                          courseEnrollmentId: this.props.enrollmentId,
                          chapterId: this.props.chapterId,
                          videoId: this.props.videoId,
                          videoTimestamp: seconds,
                        },
                      },
                    });
                  }
                }}
                onEnded={() => {
                  updateProgress({
                    variables: {
                      update_course_progress: {
                        courseEnrollmentId: this.props.enrollmentId,
                        chapterId: this.props.chapterId,
                        videoId: this.props.videoId,
                        completed: true,
                      },
                    },
                  });
                }}
              />
            </PlayerWrapper>
          );
        }}
      </Mutation>
    );
  }
}

export default Player;
