import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

const Login = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .login__button {
    margin: 1rem auto;
  }
`;

export class LoginScreen extends Component {
  state = {
    message: 'You must be signed in to view this page.',
    redirect: false,
  };

  // Reach page with token
  componentWillMount() {
    // Grab token
    const { params } = this.props.match;

    if (typeof params.token !== 'undefined') {
      // Push token into localStorage.token
      localStorage.setItem('token', params.token);
      this.setState({ redirect: true });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    return (
      <Login>
        <h1>{this.state.message}</h1>
        <a
          href={`${process.env.REACT_APP_SERVER_URL}/login`}
          className="c-button c-button--yellow login__button"
        >
          Login
        </a>
        <a
          href={`${process.env.REACT_APP_SERVER_URL}/classes`}
          className="color-green"
        >
          Back to online experiences
        </a>
      </Login>
    );
  }
}

export default LoginScreen;
