import React from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Colors from '../util/Colors';

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default ({ children, ...props }) => {
  return (
    <LoaderWrapper>
      <Loader type="Oval" color={Colors.green} />
    </LoaderWrapper>
  );
};
