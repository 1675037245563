import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import Colors from '../util/Colors';

import logo from '../images/logo.svg';

const Logo = styled.div`
  display: flex;

  a {
    width: 120px;
  }

  svg {
    max-height: 40px;
    fill: ${Colors.navy};

    .logo__heart {
      fill: ${Colors.yellow};
    }
  }
`;

export default function(props) {
  return (
    <Logo {...props}>
      <Link to="/">
        <SVG src={logo} alt="Happily Logo" />
      </Link>
    </Logo>
  );
}
