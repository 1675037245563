import React, { Component } from 'react';
import { Section } from 'bloomer';

import LayoutMain from '../components/Layout/Main';
import Query from '../components/Query';
import MyClasses from '../components/MyClasses';
import LatestVideo from '../components/LatestVideo';

import { getIndexInfo } from '../graphql';
import latestProgressOrFirstVideo from '../util/latestProgressOrFirstVideo';

const buildLink = ({ classId, videoId }) => {
  return `/classes/${classId}/lessons/${videoId}`;
};

class IndexScreen extends Component {
  render() {
    return (
      <Query query={getIndexInfo}>
        {({ courseEnrollments, allProgresses }) => {
          const latestEnrollment =
            courseEnrollments[courseEnrollments.length - 1];
          return (
            // Gives header latest enrollment
            <LayoutMain
              type="class"
              title={latestEnrollment.course.title}
              {...latestProgressOrFirstVideo(latestEnrollment, allProgresses)}
              link={{
                url: buildLink(
                  latestProgressOrFirstVideo(latestEnrollment, allProgresses)
                ),
                text:
                  latestEnrollment.courseProgresses.length > 0
                    ? 'Resume'
                    : 'Start',
                enrollmentId: latestEnrollment.id,
              }}
            >
              <Section>
                <LatestVideo
                  enrollment={latestEnrollment}
                  progresses={allProgresses}
                  isHidden={['desktop', 'widescreen']}
                />
                <MyClasses
                  courseEnrollments={courseEnrollments}
                  allProgresses={allProgresses}
                  isHidden="touch"
                />
              </Section>
            </LayoutMain>
          );
        }}
      </Query>
    );
  }
}

export default IndexScreen;
