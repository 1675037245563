import React from 'react';
import styled from 'styled-components';
import { Columns, Column, withHelpersModifiers } from 'bloomer';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import Colors from '../util/Colors';

import videoIcon from '../images/video.svg';

const Sidebar = styled.div`
  background: ${Colors.white};
  min-height: 70vh;

  .accordion {
    margin-bottom: 10rem;
  }

  .sidebar__heading {
    padding: 50px 63px;
  }

  .lesson__title {
  }

  .chapter__icon {
    height: 25px;
  }

  .chapter__title {
    font-size: 16px;
    color: ${Colors.navy};
  }

  .chapter__status {
    margin-right: 0;

    svg {
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }
  }
`;

const ChapterAccordion = (props) => {
  return (
    <Sidebar isSize={3} style={{ padding: 0 }} className={props.className}>
      <h4 className="sidebar__heading">
        <Link
          to={{
            pathname: `/classes/${props.courseEnrollment.course.id}`,
            state: { enrollmentId: props.courseEnrollment.id },
          }}
          className="color-navy"
        >
          Online Experiences home
        </Link>
      </h4>
      <Accordion>
        {props.courseEnrollment.course.chapters
          .sort((a, b) => {
            return a.chapterNumber - b.chapterNumber;
          })
          .map((lesson) => {
            return (
              <AccordionItem key={lesson.id}>
                <Columns style={{ margin: 0 }}>
                  <Column isSize={11} isOffset={1}>
                    <AccordionItemTitle>
                      <h1 className="lesson__number">{lesson.chapterNumber}</h1>
                      <h5 className="lesson__title">{lesson.title}</h5>
                    </AccordionItemTitle>
                  </Column>
                </Columns>
                <AccordionItemBody>
                  <Columns>
                    <ul className="chapter__list">
                      {lesson.videos.map((chapter) => (
                        <Column key={chapter.id} isSize={10} isOffset={2}>
                          <li className="chapter__item" key={chapter.title}>
                            <div className="chapter__icon">
                              <SVG src={videoIcon} alt="video" />
                            </div>
                            <Link
                              to={{
                                pathname: chapter.id,
                                state: {
                                  ...props.location.state,
                                  videoId: chapter.id,
                                },
                              }}
                              className="chapter__title"
                              replace
                            >
                              {chapter.title}
                            </Link>
                            {/* <div className="chapter__status">
                            {chapter.completed ? (
                              <SVG src={checked} alt="chapter complete" />
                            ) : (
                              <CircularProgressbar
                                percentage={index * 20}
                              />
                            )}
                          </div> */}
                          </li>
                        </Column>
                      ))}
                    </ul>
                  </Columns>
                </AccordionItemBody>
              </AccordionItem>
            );
          })}
      </Accordion>
    </Sidebar>
  );
};

export default withHelpersModifiers(ChapterAccordion);
