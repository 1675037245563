import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import play from '../images/play.svg';

const PlayButton = styled.div`
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
`;

export default function(props) {
  return (
    <PlayButton>
      <Link
        to={{
          pathname: props.url,
          state: { enrollmentId: props.enrollmentId, videoId: props.videoId },
        }}
      >
        <SVG src={play} alt="Play Button" />
      </Link>
    </PlayButton>
  );
}
