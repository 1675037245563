import React, { Component } from 'react';

import LessonHeader from '../LessonHeader';
import Footer from '../Footer';

export default class LayoutLesson extends Component {
  render() {
    return (
      <>
        <LessonHeader />
        {this.props.children}
        <Footer />
      </>
    );
  }
}
