import React from 'react';
import styled from 'styled-components';

import LessonNavigation from './LessonNavigation';

import Colors from '../util/Colors';

const StyledHeader = styled.header`
  background: ${Colors.white};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: contain;
`;

const LessonHeader = (props) => (
  <StyledHeader>
    <LessonNavigation />
  </StyledHeader>
);

export default LessonHeader;
