import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import VideoAttachments from './VideoAttachments';

const Details = styled.div`
  padding: 0 25px;
  margin-bottom: 5rem;

  @media screen and (min-width: 1088px) {
    margin-bottom: 10rem;
    padding: 0 90px;
  }

  .content {
    &__download {
      display: flex;
      align-items: center;

      svg {
        height: 25px;
        width: 20px;
        margin-right: 10px;
      }
    }

    &__cta {
      margin-top: 30px;
    }
  }
`;

const Title = styled.h2`
  margin-top: 50px;
  margin-bottom: 20px;
`;

const Subtitle = styled.h4`
  font-weight: 400;
  margin-bottom: 20px;
`;
const Description = styled.p``;

const VideoDetails = (props) => {
  return (
    <Details>
      <Title hasTextColor="info">{props.currentVideo.title}</Title>
      <Subtitle>Lesson notes</Subtitle>
      <Description>{props.currentVideo.description}</Description>
      {props.currentVideo.attachments.length > 0 && (
        <VideoAttachments
          attachments={props.currentVideo.attachments}
          isHidden="touch"
        />
      )}
      {props.nextVideo && (
        <Link
          to={{
            pathname: props.nextVideo.id,
            state: {
              ...props.location.state,
              videoId: props.nextVideo.id,
            },
          }}
          className="c-button c-button--yellow content__cta"
        >
          Next
        </Link>
      )}
    </Details>
  );
};

export default VideoDetails;
