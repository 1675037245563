import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Columns, Column, Image } from 'bloomer';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import styled from 'styled-components';
// import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SVG from 'react-inlinesvg';

import Query from '../components/Query';
import ClassThumbnail from '../components/ClassThumbnail';
import LayoutMain from '../components/Layout/Main';
import Colors from '../util/Colors';

import { getClassInfo } from '../graphql';

import chevron from '../images/chevron.svg';
import videoIcon from '../images/video.svg';
// import checked from '../images/checked.svg';
// import unchecked from '../images/unchecked.svg';
import dr_les from '../images/dr_les.png';

const SectionHeading = styled.h1`
  margin-bottom: 20px;
  font-size: 30px;
`;

// const OverviewList = styled.ul`
//   margin-bottom: 50px;

//   li {
//     color: ${Colors.navy};
//   }
// `;

// const OverviewListHeading = styled.h3`
//   margin-bottom: 20px;
// `;

const AboutImageBg = styled.div`
  background: ${Colors.yellow};
  height: 110px;
  width: 165px;
  overflow: hidden;
  margin-bottom: 32px;
`;

const getNextThreeVideos = (courseEnrollment, videoId) => {
  const videos = courseEnrollment.course.chapters
    .sort((a, b) => a.chapterNumber - b.chapterNumber)
    .map((chapter) => chapter.videos)
    .flat();

  const latestIndex = videos.findIndex((v) => v.id === videoId) + 1;

  return videos.slice(latestIndex, latestIndex + 3);
};

const secondsToMs = (d) => {
  d = Number(d);

  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  return ('0' + m).slice(-1) + ':' + ('0' + s).slice(-2);
};

const latestProgressOrFirstVideo = (enrollment, progresses) => {
  if (progresses.length > 0) {
    // Get latest updated progress
    const { currentVideo } = progresses.sort((a, b) => {
      const aDate = new Date(a.updatedAt);
      const bDate = new Date(b.updatedAt);

      return bDate - aDate;
    })[0];
    return currentVideo;
  } else {
    return enrollment.course.chapters[0].videos[0];
  }
};

export default class ClassScreen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Query query={getClassInfo} variables={{ id: this.props.enrollmentId }}>
        {({ courseEnrollment, courseProgresses }) => {
          const firstVideo = courseEnrollment.course.chapters[0].videos[0];
          const latestVideo = latestProgressOrFirstVideo(
            courseEnrollment,
            courseProgresses
          );
          const nextUp = getNextThreeVideos(courseEnrollment, latestVideo.id);
          return (
            <LayoutMain
              type="lesson"
              title={`Lesson ${latestVideo.chapter.chapterNumber}`}
              subtitle={latestVideo.title}
              classId={courseEnrollment.course.id}
              videoId={latestVideo.id}
              link={{
                url: `/classes/${courseEnrollment.course.id}/lessons/${
                  latestVideo.id
                }`,
                text:
                  courseEnrollment.courseProgresses.length > 0
                    ? 'Resume'
                    : 'Start',
                enrollmentId: courseEnrollment.id,
              }}
            >
              <Container style={{ paddingTop: 50 }}>
                <Tabs>
                  <TabList>
                    <Tab>
                      <span>Course content</span>
                    </Tab>
                    <Tab>
                      <span>Overview</span>
                    </Tab>
                    {/* <Tab>
                      <span>Q &amp; A</span>
                    </Tab> */}
                  </TabList>
                  <TabPanel>
                    {nextUp.length > 0 && (
                      <Container style={{ marginBottom: 150 }}>
                        <SectionHeading>Next Videos</SectionHeading>
                        <Columns isMultiline>
                          {nextUp.map((video) => (
                            <Column key={`next_${video.id}`} isSize="1/3">
                              <ClassThumbnail
                                title={video.title}
                                classId={courseEnrollment.course.id}
                                videoId={video.id}
                                detail={{
                                  type: 'time',
                                  data: secondsToMs(video.lengthInSeconds),
                                  enrollmentId: courseEnrollment.id,
                                }}
                                playButton
                              />
                            </Column>
                          ))}
                        </Columns>
                      </Container>
                    )}
                    <Container>
                      <Accordion>
                        {courseEnrollment.course.chapters
                          .sort((a, b) => {
                            return a.chapterNumber - b.chapterNumber;
                          })
                          .map((chapter) => {
                            return (
                              <AccordionItem key={chapter.id}>
                                <AccordionItemTitle>
                                  {/* <div className="lesson__status">
                                  {lesson.completed ? (
                                    <SVG src={checked} alt="completed" />
                                  ) : (
                                    <CircularProgressbar percentage={index * 20} />
                                  )}
                                </div> */}
                                  <h1 className="lesson__number">
                                    {chapter.chapterNumber}
                                  </h1>
                                  <h5 className="lesson__title">
                                    {chapter.title}
                                  </h5>
                                  <div className="lesson__total">
                                    {chapter.videos.length}{' '}
                                    {chapter.videos.length > 1
                                      ? 'Chapters'
                                      : 'Chapter'}
                                  </div>
                                  <div className="lesson__arrow">
                                    <SVG src={chevron} alt="chevron" />
                                  </div>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                  <Columns>
                                    <ul className="chapter__list">
                                      {chapter.videos.map((video) => (
                                        <Column
                                          key={video.id}
                                          isSize={10}
                                          isOffset={2}
                                        >
                                          <li
                                            className="chapter__item"
                                            key={video.title}
                                          >
                                            <div className="chapter__icon">
                                              <SVG
                                                src={videoIcon}
                                                alt="video"
                                              />
                                            </div>
                                            <Link
                                              to={{
                                                pathname: `/classes/${
                                                  courseEnrollment.course.id
                                                }/lessons/${video.id}`,
                                                state: {
                                                  enrollmentId:
                                                    courseEnrollment.id,
                                                  videoId: video.id,
                                                },
                                              }}
                                              className="chapter__link"
                                            >
                                              <div className="chapter__title">
                                                {video.title}
                                              </div>
                                            </Link>
                                            {/* <div className="chapter__status">
                                            <SVG
                                              src={
                                                chapter.completed
                                                  ? checked
                                                  : unchecked
                                              }
                                              alt={
                                                chapter.completed
                                                  ? 'chapter complete'
                                                  : 'chapter not complete'
                                              }
                                            />
                                          </div> */}
                                          </li>
                                        </Column>
                                      ))}
                                    </ul>
                                  </Columns>
                                </AccordionItemBody>
                              </AccordionItem>
                            );
                          })}
                      </Accordion>
                    </Container>
                  </TabPanel>
                  <TabPanel>
                    <Container style={{ marginBottom: 150 }}>
                      <SectionHeading>About</SectionHeading>
                      <Columns>
                        <Column isSize="1/4">
                          <ClassThumbnail
                            videoId={firstVideo.id}
                            title={firstVideo.title}
                            detail={{
                              type: 'time',
                              data: secondsToMs(firstVideo.lengthInSeconds),
                            }}
                            style={{ marginBottom: 100 }}
                          />
                        </Column>
                        <Column>
                          <div className="description">
                            <p>{courseEnrollment.course.description}</p>
                          </div>
                        </Column>
                      </Columns>
                      <Columns>
                        <Column isSize="1/4">
                          {/* <OverviewListHeading>Tools needed</OverviewListHeading>
                          <OverviewList>
                            <li>Computer with internet</li>
                            <li>Printer (optional)</li>
                            <li>Notebook</li>
                            <li>Headphones or speaker</li>
                          </OverviewList>
                          <OverviewListHeading>Features</OverviewListHeading>
                          <OverviewList>
                            <li>12 hours of video</li>
                            <li>15 PDF notes</li>
                            <li>3 assessments</li>
                            <li>English</li>
                            <li>Captions available</li>
                          </OverviewList> */}
                        </Column>
                      </Columns>
                    </Container>
                  </TabPanel>
                  {/* <TabPanel>
                    <Container style={{ marginBottom: 150 }}>
                      <SectionHeading>Common questions</SectionHeading>
                      <div className="common-questions">
                        <div className="common-questions__top-row">
                          <Columns isMultiline>
                            <Column isSize={3}>
                              <div className="common-questions__question">
                                <h4 className="common-questions__question-heading">
                                  Who is Dr. Les?
                                </h4>
                                <p className="common-questions__question-paragraph">
                                  When we send you a date, we’ll ship it to you
                                  via USPS Priority Mail.  Digital subscribers
                                  receive downloadable dates through their user
                                  account & email.
                                </p>
                              </div>
                            </Column>
                            <Column isSize={3} isOffset={1}>
                              <div className="common-questions__question">
                                <h4 className="common-questions__question-heading">
                                  In the “Gender Gap” lesson, you mention X,
                                  what do you mean?
                                </h4>
                                <p className="common-questions__question-paragraph">
                                  Your dates arrive around the 15th of every
                                  month. You’ll get your first date the month
                                  after you sign up. (Example: sign up anytime
                                  in June & you’ll get July’s date.)
                                </p>
                              </div>
                            </Column>
                            <Column isSize={3} isOffset={1}>
                              <div className="common-questions__question">
                                <h4 className="common-questions__question-heading">
                                  Can we cancel anytime?
                                </h4>
                                <p className="common-questions__question-paragraph">
                                  Yes! Monthly subscriptions can be cancelled at
                                  any time. If you subscribe to a 3- or 6-month
                                  plan, your cancellation will take effect prior
                                  to your next renewal, but you’ll still get the
                                  remaining dates that you’ve paid for on your
                                  plan.
                                </p>
                              </div>
                            </Column>
                          </Columns>
                        </div>
                        <div className="common-questions__bottom-row">
                          <Columns>
                            <Column isSize={3}>
                              <div className="common-questions__question">
                                <h4 className="common-questions__question-heading">
                                  How do you know what we like?
                                </h4>
                                <p className="common-questions__question-paragraph">
                                  Magic. Really though—we’ll ask you a series of
                                  questions to learn about your interests,
                                  personalities, likes, & dislikes. This helps
                                  us curate the perfect date for you. This is
                                  also where you’d let us know about any
                                  allergies or dietary restrictions you may
                                  have.
                                </p>
                              </div>
                            </Column>
                            <Column isSize={3} isOffset={1}>
                              <div className="common-questions__question">
                                <h4 className="common-questions__question-heading">
                                  Why the wait on my date?
                                </h4>
                                <p className="common-questions__question-paragraph">
                                  We want to make sure that every Datebox is an
                                  awesome experience for you. We take the time
                                  to look at your preferences & send the perfect
                                  date every time.
                                </p>
                              </div>
                            </Column>
                          </Columns>
                        </div>
                      </div>
                    </Container>
                  </TabPanel> */}
                </Tabs>
              </Container>
            </LayoutMain>
          );
        }}
      </Query>
    );
  }
}

/*

        <Row className="mb3">
          <Query query={getMyEnrollments} fetchPolicy="network-only">
            {({ me: { courseEnrollments }, client }) => {
              return courseEnrollments.map((enrollment) => {
                const latestProgress = enrollment.courseProgresses.reduce(
                  (acc, curr) => {
                    const accDate = new Date(acc.updatedAt);
                    const currDate = new Date(curr.updatedAt);
                    return currDate > accDate ? curr : acc;
                  }
                );

                const data = buildData(enrollment, latestProgress);
                return (
                  <Link
                    key={enrollment.id}
                    to={`/course/${enrollment.id}`}
                    onClick={() => {
                      client.writeQuery({
                        query: getNowPlaying,
                        data: data,
                      });
                    }}
                  >
                    <Card {...enrollment.course} />
                  </Link>
                );
              });
            }}
          </Query>
        </Row>
*/
