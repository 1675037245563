import React from 'react';
import SVG from 'react-inlinesvg';
import { withHelpersModifiers } from 'bloomer';
import styled from 'styled-components';

import Colors from '../util/Colors';

import textIcon from '../images/text.svg';

const Attachment = styled.a`
  display: flex;
  align-items: center;
  margin: 1rem 0;

  &:last-of-type {
    border-bottom: 1px solid ${Colors.yellow};
    padding-bottom: 1.5rem;
  }

  svg {
    height: 25px;
    width: 20px;
    margin-right: 10px;
  }
`;

const VideoAttachments = (props) => {
  return props.attachments.map((attachment) => {
    return (
      <Attachment
        className={`thin content__download ${props.className}`}
        href={attachment.url}
        key={attachment.id}
      >
        <SVG src={textIcon} alt={attachment.metadata} />
        <h6>{attachment.filename}</h6>
      </Attachment>
    );
  });
};

export default withHelpersModifiers(VideoAttachments);
